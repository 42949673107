import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import './styles.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { querySidebarData } from '../../data/queries';
import { SidebarQueryData } from '../../data/types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

type SidebarContextType = {
  showSidebar: boolean,
  toggleSidebar: () => void,
}

const SidebarContext = createContext<SidebarContextType>({
  showSidebar: false,
  toggleSidebar: () => {},
})

export const useSidebar = () => useContext(SidebarContext)

export const SidebarProvider = ({children}: PropsWithChildren) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar)
  }, [showSidebar])

  return (
    <SidebarContext.Provider value={{showSidebar, toggleSidebar}}>
      {children}
    </SidebarContext.Provider>
  )
}

const Sidebar = () => {
  const [sidebarContent, setSidebarContent] = useState<SidebarQueryData>()
  const {showSidebar,toggleSidebar} = useSidebar();
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (!showSidebar && expanded) {
      setExpanded(false)
    }
  }, [showSidebar, expanded])

  useEffect(() => {
    querySidebarData().then(a => setSidebarContent(a))
  }, [])

  if (!sidebarContent) {
    return null
  }

  return (
    <>
      <div className={showSidebar ? 'sidebar' : 'sidebar hide'}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img 
            src={sidebarContent.data.sidebar.items[0].logo.url}
            style={{height: '12em'}}
            alt='logo'/>
        </div>
        {sidebarContent.data.sidebar.items[0].entries.items.map((a) => (
          <Accordion key={a.sys.id} disableGutters square elevation={0} expanded={expanded === a.sys.id} onChange={handleChange(a.sys.id)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              {a.header}
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{__html: documentToHtmlString(a.body.json)}} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

        <div
          className={showSidebar ? 'sidebar-overlay show' : 'sidebar-overlay'}
          onClick={toggleSidebar} 
          style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999999, animation: 'fadeIn 5s'}}/>

    </>
  );
}

export default Sidebar