import { DivIcon } from "leaflet"
import { LocationType } from "../../data/types"

const circleSvgString = (color: string = 'black') => (`
  <svg 
    width="24px" 
    height="24px" 
    stroke-width="1.5" 
    viewBox="0 0 24 24" 
    fill="${color}" 
    xmlns="http://www.w3.org/2000/svg" 
    color="#000000"
  >
    <path 
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" 
      stroke="${color}" 
      stroke-width="1.5" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    >
    </path>
  </svg>
`)

const xMarkSvgString = (color: string = 'black') => (`
  <svg 
    width="24px" 
    height="24px" 
    stroke-width="1.5" 
    viewBox="4 4 16 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    color="#000000"
  >
    <path 
      d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" 
      stroke="${color}" 
      stroke-width="3" 
      stroke-linecap="round" 
      stroke-linejoin="round"
    >
    </path>
  </svg>
`)

const publicoSvgString = `
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.3672" cy="13.6324" r="11.791" fill="white" fill-opacity="1" stroke="#4E4949" stroke-width="2"/>
    <path d="M6.71436 9.00731C6.71436 8.45502 7.16207 8.00731 7.71436 8.00731H18.1015C18.6538 8.00731 19.1015 8.45503 19.1015 9.00731V18.3668C19.1015 18.9191 18.6538 19.3668 18.1015 19.3668H7.71436C7.16207 19.3668 6.71436 18.9191 6.71436 18.3668V9.00731Z" stroke="#4E4949" stroke-width="2" stroke-linejoin="round"/>
    <path d="M10.8525 8.03496L10.8525 19.1861" stroke="#4E4949" stroke-width="2"/>
    <path d="M14.9634 8.71277V18.552" stroke="#4E4949" stroke-width="2"/>
  </svg>
`

const cladestinoSvgString = `
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.4619" cy="13.0516" r="11.791" fill="white" fill-opacity="1" stroke="#4E4949" stroke-width="2"/>
    <path d="M7.57422 10.5177C7.57422 10.1267 7.80204 9.77163 8.15741 9.60869L12.8762 7.44496C13.1343 7.32663 13.4305 7.32357 13.6909 7.43654L18.7471 9.62964C19.1127 9.78819 19.3492 10.1486 19.3492 10.5471V17.2831C19.3492 17.8354 18.9015 18.2831 18.3492 18.2831H8.57422C8.02193 18.2831 7.57422 17.8354 7.57422 17.2831V10.5177Z" stroke="#4E4949" stroke-width="2" stroke-linejoin="round"/>
  </svg>
`
const createCircleIcon = (color: string) => {
  const icon = document.createElement('div');
  icon.classList.add('map-pin')
  icon.classList.add('circle')
  icon.innerHTML = circleSvgString(color)
  
  return new DivIcon({ 
    html: icon,
    iconSize: [13,13],
  })
}

const createXMarkIcon = (color: string) => {
  const icon = document.createElement('div');
  icon.classList.add('map-pin')
  icon.classList.add('xMark')
  icon.innerHTML = xMarkSvgString(color)
  
  return new DivIcon({ 
    html: icon,
    iconSize: [24,24],
  })
}

const createPublicoIcon = () => {
  const icon = document.createElement('div');
  icon.classList.add('map-pin')
  icon.classList.add('publico')
  icon.innerHTML = publicoSvgString
  
  return new DivIcon({ 
    html: icon,
    iconSize: [24,24],
  })
}

const createClandestinoIcon = () => {
  const icon = document.createElement('div');
  icon.classList.add('map-pin')
  icon.classList.add('clandestino')
  icon.innerHTML = cladestinoSvgString
  
  return new DivIcon({ 
    html: icon,
    iconSize: [24,24],
  })
}

export const getMarkerIcon = (type: LocationType, color: string) => {
  switch (type) {
    case LocationType.LugarDetencion:
      return createXMarkIcon(color)
      case LocationType.LugarMilitancia:
      return createCircleIcon(color)
    case LocationType.RecintoPublico:
      return createPublicoIcon()
    case LocationType.RecintoClandestino:
      return createClandestinoIcon()
  }
}