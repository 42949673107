import logo from '../../logo-wm-londres.png';
import { useSidebar } from '../Sidebar';

const Icon = () => {
  const { toggleSidebar } = useSidebar();
  return (
    <img 
      src={logo}
      style={{width: '200px', position: 'absolute', top: 0, left: 0, zIndex: 9999, cursor: 'pointer'}} 
      alt='logo'
      onClick={toggleSidebar}/>
  );
}

export default Icon;