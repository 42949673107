import {Document} from '@contentful/rich-text-types'
export enum LocationType {
  LugarMilitancia = 'lugar-militancia',
  LugarDetencion = 'lugar-detencion',
  RecintoPublico = 'recinto-publico-de-detencion',
  RecintoClandestino = 'recinto-clandestino-de-detencion',
}

type ContentfulIdentifiable = {
  sys: {
    id: string
  }
}

export type LocationFields = {
  name: string,
  type: LocationType,
  coordinates: {
    lat: number,
    lon: number
  }
}

export type LocationData = LocationFields & ContentfulIdentifiable

export type PersonFields = {
  name: string,
  date: string,
  age: number,
  militancy?: string[],
  url: string,
  locations: {
    items: LocationData[]
  },
  imageUrl?: string
}

export type PersonData = PersonFields & ContentfulIdentifiable

export type ContentfulPeopleQueryFilter = Partial<{
  finalDestination_in: FinalDestinationFilters[],
  age_gte: number,
  age_lte: number,
  militancy_contains_some: MilitancyFilters[],
  name_contains: string,
  locations: {
    sys: {
      id_in: string[]
    }
  }
}>

export type PeopleQueryFilter = Partial<{
  finalDestination: FinalDestinationFilters[],
  age: number[],
  militancy: MilitancyFilters[],
  name: string,
  locations: string[],
}>

export enum FinalDestinationFilters {
  EP = 'EP',
  DDDD = 'DDDD',
}
export enum MilitancyFilters {
  MIR = 'MIR',
  PC = 'PC',
  PS = 'PS',
  FER = 'FER',
  JJCC = 'JJCC',
  LigaComunista = 'Liga Comunista',
  PartidoNacional = 'Partido Nacional',
  FTR = 'FTR',
  JS = 'JS',
  MAPU = 'MAPU',
  DC = 'DC',
}

export type PeopleQueryData = {
  data: {
    people: {
      items: PersonData[]
    }
  }
}

export type SidebarFields = {
  logo: {
    url: string
  },
  entries: {
    items: ({
      header: string,
      body: {
        json: Document
      }
    } & ContentfulIdentifiable)[]
  }
}


export type SidebarQueryData = {
  data: {
    sidebar: {
      items: SidebarFields[]
    }
  }
}

export type LocationFilterFields = {
  name: string,
  sys: {
    id: string,
  }
}
export type LocationQueryData = {
  data: {
    locations: {
      items: LocationFilterFields[]
    }
  }
}