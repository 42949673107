import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import Icon from './components/Icon';
import Map from './components/Map';
import Results from './components/Results';
import Sidebar, { SidebarProvider } from './components/Sidebar';
import { LocationsProvider, PersonDataProvider } from './data/provider';
import ScreenWidthProvider from './providers/ScreenWidth';


const theme = createTheme({
  typography: {
    fontFamily: 'Source Serif Pro',
  }
});
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ScreenWidthProvider>
        <LocationsProvider>
          <PersonDataProvider>
            <SidebarProvider>
              <div className="App">
                <Icon />
                <Map />
                <Results />
                <Sidebar />
              </div>
            </SidebarProvider>
          </PersonDataProvider>
        </LocationsProvider>
      </ScreenWidthProvider>
    </ThemeProvider>
  );
}

export default App;
