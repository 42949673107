import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react"

export enum ScreenBreakpoint {
  S,
  M,
  L,
  XL
}

interface ScreenWidthContextType {
  screenWidth: number;
  breakpoint: ScreenBreakpoint;
  isDivided: boolean;
}

const ScreenWidthContext = createContext<ScreenWidthContextType>({
  screenWidth: 0,
  breakpoint: ScreenBreakpoint.S,
  isDivided: false
})

export const useScreenWidth = () => useContext(ScreenWidthContext)

const ScreenWidthProvider = ({children}: PropsWithChildren) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const breakpoint = useMemo(() => {
    if (screenWidth <= 480) {
      return ScreenBreakpoint.S
    } else if (screenWidth <= 768) {
      return ScreenBreakpoint.M
    } else if (screenWidth <= 1024) {
      return ScreenBreakpoint.L
    } else {
      return ScreenBreakpoint.XL
    }
  }, [screenWidth])
  const isDivided = useMemo(() => {
    return [ScreenBreakpoint.XL, ScreenBreakpoint.L].includes(breakpoint)
  }, [breakpoint])
  return (
    <ScreenWidthContext.Provider value={{screenWidth, breakpoint, isDivided}}>
      {children}
    </ScreenWidthContext.Provider>
  )
}

export default ScreenWidthProvider