import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { queryLocationData, queryPersonData } from "./queries";
import { PeopleQueryData, ContentfulPeopleQueryFilter, PersonData, PeopleQueryFilter, LocationData, LocationFilterFields } from "./types";

function transformPersonData(content: PeopleQueryData): Record<string, PersonData> {
  return content.data.people.items.reduce((acc, val) => ({ ...acc, [val.sys.id]: val }), {})
}

interface PersonDataContextType {
  isLoading: boolean;
  isError: boolean;
  people: Record<string, PersonData>;
  selected: string[];
  select: (id: string) => void;
  selectAll: () => void;
  unselect: (id: string) => void;
  unselectAll: () => void;
  selectedFilters: PeopleQueryFilter;
  setFilters: (obj: PeopleQueryFilter) => void;
  clearFilters: () => void;
  selectedLocations: LocationData[];
}

const PersonDataContext = createContext<PersonDataContextType>({
  isLoading: false,
  isError: false,
  people: {},
  selected: [],
  select: function (id: string): void {
    throw new Error("Function not implemented.");
  },
  selectAll: function (): void {
    throw new Error("Function not implemented.");
  },
  unselect: function (id: string): void {
    throw new Error("Function not implemented.");
  },
  unselectAll: function (): void {
    throw new Error("Function not implemented.");
  },
  selectedFilters: {},
  clearFilters: function (): void {
    throw new Error("Function not implemented.");
  },
  setFilters: function (obj: PeopleQueryFilter): void {
    throw new Error("Function not implemented.");
  },
  selectedLocations: []
});

export const usePersonData = () => useContext(PersonDataContext);

export const PersonDataProvider = ({children}: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [people, setPeople] = useState<Record<string, PersonData>>({});
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<PeopleQueryFilter>({})

  const selectedLocations = useMemo(() => {
    return selectedPeople.map(id => people[id]).filter(p => !!p).flatMap(p => p.locations.items)
  }, [people, selectedPeople])
  
  const select = useCallback((id: string) => {
    setSelectedPeople([...selectedPeople, id])
  }, [selectedPeople])

  const selectAll = useCallback(() => {
    setSelectedPeople(Object.keys(people));
  }, [people])

  const unselect = useCallback((id: string) => {
    setSelectedPeople(selectedPeople.filter(selected => selected !== id))
  }, [selectedPeople])

  const unselectAll = useCallback(() => {
    setSelectedPeople([]);
  }, [])

  const clearFilters = useCallback(() => {
    setSelectedFilters({finalDestination: [], age: []});
  }, [])

  const setFilters = useCallback((obj: PeopleQueryFilter) => {
    setSelectedFilters(obj)
  }, [])

  const transformedFilters: ContentfulPeopleQueryFilter = useMemo(() => {
    const query: ContentfulPeopleQueryFilter = {}
    if (selectedFilters.finalDestination && selectedFilters.finalDestination.length !== 0) {
      query.finalDestination_in = selectedFilters.finalDestination
    }
    if (selectedFilters.locations && selectedFilters.locations.length !== 0) {
      query.locations = {sys: {id_in: selectedFilters.locations}}
    }
    if (selectedFilters.age) {
      query.age_gte = Math.min(...selectedFilters.age)
      query.age_lte = Math.max(...selectedFilters.age)
    }
    if (selectedFilters.militancy && selectedFilters.militancy.length > 0) {
      query.militancy_contains_some = selectedFilters.militancy
    }
    if (selectedFilters.name) {
      query.name_contains = selectedFilters.name
    }
    return query
  }, [selectedFilters])

  useEffect(() => {
    Promise.resolve(setIsLoading(true))
      .then(queryPersonData(transformedFilters))
      .then(transformPersonData)
      .then(setPeople)
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }, [selectedFilters])

  useEffect(() => {
    setSelectedPeople(selected => selected.filter(p => Object.keys(people).includes(p)))
  }, [people])

  return (
    <PersonDataContext.Provider value={{
      isError,
      isLoading,
      people,
      selected: selectedPeople,
      select,
      unselect,
      selectAll,
      unselectAll,
      selectedFilters,
      setFilters,
      clearFilters,
      selectedLocations,
    }}>
      {children}
    </PersonDataContext.Provider>
  );
}

const LocationsContext = createContext<LocationFilterFields[]>([])
export const useLocations = () => useContext(LocationsContext)

export const LocationsProvider = ({children}: PropsWithChildren) => {
  const [locations, setLocations] = useState<LocationFilterFields[]>([])
  useEffect(() => { queryLocationData().then(d => setLocations(d.data.locations.items)) }, [])
  return (
    <LocationsContext.Provider value={locations}>
      {children}
    </LocationsContext.Provider>
  )
}