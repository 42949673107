import { AttributionControl, MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet"
import { LocationPopupProps, Trajectory } from "../Trajectory"
import { usePersonData } from "../../data/provider"
import { getMarkerIcon } from "../Trajectory/icons";
import { useMemo } from "react";
import './styles.css'

const LocationPopup = ({location}: LocationPopupProps) => {
  return (
    <Popup className={'location-popup'} closeButton={false}>
      <h4>{location.name}</h4>
    </Popup>
  )
}

const Location = ({location, fill}: LocationPopupProps & { fill: string}) => {
  return (
    <Marker
      key={location.sys.id}
      position={[location.coordinates.lat, location.coordinates.lon]} 
      icon={getMarkerIcon(location.type, fill)}
    >
      <LocationPopup location={location} />
    </Marker>
  )
}

const Map = () => {
  const { people, selected, selectedLocations } = usePersonData();
  const trajectoryColor = useMemo(() => {
    return 'black'
  }, [])
  
  return (
    <MapContainer 
      style={{height: '100vh', width: '100vw', position: 'absolute', top: 0, left: 0}} 
      center={[-33.44410457,-70.6481971]} 
      zoom={12}
      minZoom={5}
      maxZoom={18} 
      attributionControl={false}
      zoomControl={false}
    >
      <AttributionControl prefix={false} position={'topright'}  />
      <ZoomControl position={'topright'} />
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        // attribution='
        //   &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> |
        //   &copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> |
        //   &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> |
        //   &copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>
        // '
      />
      {selectedLocations.map(l => (
        l && <Location location={l} fill={trajectoryColor} />
      ))}
      {people && (
        selected.map(key => {
          return (
            <>
              {people[key] && <Trajectory key={key} person={people[key]} />}
            </>
          );
        })
      )}
    </MapContainer>
  )
}

export default Map